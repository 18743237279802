import { useState, useEffect } from 'react';

const GeolocationOption = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const usePosition = (allowed = true) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords }: { coords: { latitude: number; longitude: number } }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error: any) => {
    setError(error.message);
  };
  useEffect(() => {
    if (navigator && navigator.geolocation && allowed) {
      navigator.geolocation.getCurrentPosition(onChange, onError, GeolocationOption);
    }
  }, []);
  return { latLong: position, error };
};

export default usePosition;
