import { EXPRESS_SURVEY_PARAMS, GOOGLE_MAP_ADDRESS_BUILD_ORDER } from 'constant/constants';
import { isArray, isEmpty, snakeCase, trim } from 'lodash';
import moment from 'moment';
import { PAGE_KEYS, RATING } from 'utils/types';

export const ORDER_TYPE = {
  DINE_IN: 'DINE_IN',
  TAKE_AWAY: 'TAKE_AWAY',
  DELIVERY: 'DELIVERY',
};

export type SurveyObject = {
  name: string;
  email: string;
  phoneNumber: string;
  contactMethod: string;
  outletId: string | null;
  typeOfOrder: string | null;
  deliveryPlatform: string | null;
  comment: string;
  orderDate?: string;
  tagsPositive?: any;
  tagsNegative?: any;
  normalizedRating: string;
  orderReferenceNumber?: string;
  staff?: { id: string; name: string; profile: string } | null;
  staffRating: string;
  staffComment: string;
};

const formatOrderDate = (orderDate: moment.Moment, orderTime: moment.Moment): string => {
  return moment(`${orderDate?.format('DD/MM/YYYY')} ${orderTime.format('hh:mm a')}`, 'DD/MM/YYYY hh:mm a').format();
};

const normalizeRating = ({ value, type }: { value: number; type: string }): string => {
  console.log('>> normalizeRating', value, type);

  if (type === RATING.THUMBS) {
    if (value === -1) return '1';
    if (value === 1) return '5';
  }
  if (type === RATING.STAR_1_3) {
    if (value === 1) return '1';
    if (value === 2) return '3';
    if (value === 3) return '5';
  }
  if (type === RATING.STAR_1_10) {
    return Math.ceil(value / 2).toString();
  }

  // default for RATING.STAR_1_5, RATING.EMOJI
  return value?.toString();
};

export const getSurveyObject = (pages: any[]): SurveyObject => {
  const surveyObject: any = {
    name: '',
    email: '',
    phoneNumber: '',
    contactMethod: '',
    comment: '',
    outletId: null,
    typeOfOrder: null,
    deliveryPlatform: null,
    orderDate: '',
    tagsPositive: {},
    tagsNegative: {},
    normalizedRating: '',
    orderReferenceNumber: undefined,
    staff: null,
    staffRating: '',
    staffComment: '',
  };

  pages.forEach((page: any) => {
    if (page.pageKey === PAGE_KEYS.TYPE_OF_ORDER && page.value) surveyObject.typeOfOrder = page.value?.key;

    if (page.pageKey === PAGE_KEYS.CHOOSE_LOCATION && page.value) surveyObject.outletId = page.value?.value;

    if (page.pageKey === PAGE_KEYS.DELIVERY_PLATFORM && surveyObject.typeOfOrder == ORDER_TYPE.DELIVERY && page.value) {
      surveyObject.deliveryPlatform = page.value?.label; //TODO* capitalize
    }

    if (page.pageKey === PAGE_KEYS.RATINGS && page.value) {
      surveyObject.normalizedRating = normalizeRating(page.value.main || page.value.rating || page.value);
    }

    if (page.pageKey === PAGE_KEYS.EXPERIENCE_DETAILS && page.value) {
      surveyObject.comment = page.value?.comment;
      if (page.value?.orderDate && page.value?.orderTime)
        surveyObject.orderDate = formatOrderDate(page.value?.orderDate, page.value?.orderTime);
      if (page.value?.orderReferenceNumber) surveyObject.orderReferenceNumber = page.value?.orderReferenceNumber;
    }

    if (page.pageKey === PAGE_KEYS.EXPERIENCE_POSITIVE && page.value) {
      if (isArray(page.value)) {
        surveyObject.tagsPositive = page.value.reduce((obj: any, item: any) => ({ ...obj, [item]: 'LIKE' }), {});
      } else {
        surveyObject.tagsPositive = Object.values(page.value)?.reduce(
          (obj: any, item: any) => ({ ...obj, [item]: 'LIKE' }),
          {},
        );
      }
    }

    if (page.pageKey === PAGE_KEYS.EXPERIENCE_NEGATIVE && page.value) {
      if (isArray(page.value)) {
        surveyObject.tagsNegative = page.value.reduce((obj: any, item: any) => ({ ...obj, [item]: 'DISLIKE' }), {});
      } else {
        surveyObject.tagsNegative = Object.values(page.value)?.reduce(
          (obj: any, item: any) => ({ ...obj, [item]: 'DISLIKE' }),
          {},
        );
      }
    }

    if (page.pageKey === PAGE_KEYS.STAFF_LIST && page.value) {
      surveyObject.staff = { id: page.value?.key, name: page.value?.name, profile: page.value?.src };
    }

    if (page.pageKey === PAGE_KEYS.STAFF_RATING && page.value) {
      surveyObject.staffRating = normalizeRating({ value: page.value?.rating, type: page.value?.type });
      surveyObject.staffComment = page.value?.comment;
    }

    if (page.pageKey === PAGE_KEYS.PERSONAL_INFORMATION && page.value) {
      surveyObject.name = page.value?.name;
      surveyObject.email = page.value?.email;
      surveyObject.phoneNumber = page.value?.phoneNumber;
      surveyObject.contactMethod = page.value?.contactMethod;
    }
  });

  return surveyObject;
};

export const normalizeAddress = (addressComponent: any) => (!isEmpty(addressComponent) ? addressComponent + ', ' : '');

export const buildGoogleMapAddress = (addressObj: any) =>
  trim(
    GOOGLE_MAP_ADDRESS_BUILD_ORDER.map((orderKey: string) => normalizeAddress(addressObj?.[orderKey])).join(''),
  ).replace(/,\s*$/, '');

const compareQueryStrings = (str1: string, str2: string) => {
  return str1?.trim?.()?.replace(/[ _-]/g, '').toLowerCase() === str2?.trim?.()?.replace?.(/[ _-]/g, '')?.toLowerCase();
};

export const findServiceType = (serviceType: string, activeSurveyTypes: any[] = []) => {
  const firstServiceType = Array.isArray(serviceType) ? serviceType?.[0] : serviceType;

  let formattedOrderType = null;
  if (firstServiceType) {
    formattedOrderType = EXPRESS_SURVEY_PARAMS[snakeCase(trim(firstServiceType))];
    if (!formattedOrderType)
      // replace space, hyphen, underscore etc from query-params and compare
      formattedOrderType = activeSurveyTypes.find(
        (type: any) =>
          type.show &&
          (compareQueryStrings(type.value, firstServiceType) ||
            type.mappedQueryParams?.some((param: string) => compareQueryStrings(param, firstServiceType))),
      )?.key;
  }
  return formattedOrderType;
};

export const queryParamCleanUp = (params?: Record<string, any>, whitelist?: string[]) => {
  const cleanQuery: any = {};

  if (!isEmpty(params)) {
    Object.keys(params)?.map((key) => {
      if (whitelist?.includes(key)) {
        cleanQuery[key] = params[key];
      } else {
        cleanQuery[key] = isArray(params[key]) ? params[key][0] : params[key];
      }
    });
  }

  return cleanQuery;
};
