import { Checkbox, Form } from 'antd';
import { basePath } from '../constant';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type AgreementProps = {
  slug?: string;
  fontColor?: string;
  customTc?: string;
  required?: boolean;
};

const Agreement = ({ slug, fontColor, customTc, required = true }: AgreementProps) => {
  const [url, setUrl] = useState(`${basePath}/${slug}/tnc`);

  useEffect(() => {
    setUrl(`${basePath}/${slug}/tnc?referrer=${window?.location?.href}`);
  }, [slug]);

  return (
    <AgreementWrapper $fontColor={fontColor}>
      <Form.Item
        valuePropName="checked"
        name="terms"
        rules={[
          () => ({
            validator(_, value) {
              if (!value && required) return Promise.reject(new Error('Please accept the terms & conditions'));
              return Promise.resolve();
            },
          }),
        ]}
      >
        <div className="each-agreement-box">
          <Checkbox />
          {customTc ? (
            <div className="forms-tc-html" dangerouslySetInnerHTML={{ __html: customTc }} />
          ) : (
            <p className="agreement-text mb-0">
              By checking this box, you agree to the following{' '}
              <a href={url ?? `${basePath}/${slug}/tnc`} target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>{' '}
              and agree to receive marketing communications from the merchant.
            </p>
          )}
        </div>
      </Form.Item>
    </AgreementWrapper>
  );
};

const AgreementWrapper = styled.div<{ $fontColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-top: 10px;

  .each-agreement-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    .agreement-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: ${(props) => props.$fontColor || '#30384c'};
      margin-bottom: 0px;
      text-align: left;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #30384c;
    border-color: #30384c;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #30384c;
  }
`;

export default Agreement;
