import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'components/Layout/RowColLayout';
import { breakpoints } from 'constant/style';
import BottomNavigation from 'components/BottomNavigation';
import TextInput from 'components/TextInput';
import { Outlet } from 'utils/types';
import VoucherLocationCard from 'components/VoucherLocationCard';

interface SelectLocationProps {
  title: string;
  outlets: Outlet[];
  onSelect?: (outlet: Outlet) => void;
  onBack?: () => void;
  className?: string;
}

const filterOutlets = (outlets: Outlet[] = [], search: string) => {
  if (!search) return outlets;
  const searchLowerCase = search.toLowerCase();

  return outlets?.filter((outlet: Outlet) => {
    if (outlet?.name?.toLowerCase()?.includes(searchLowerCase)) return true;
    if (outlet?.address?.toLowerCase()?.includes(searchLowerCase)) return true;
    if (outlet?.address2?.toLowerCase()?.includes(searchLowerCase)) return true;
    if (outlet?.city?.toLowerCase()?.includes(searchLowerCase)) return true;
    if (outlet?.state?.toLowerCase()?.includes(searchLowerCase)) return true;
  });
};

const SelectLocation = ({ title, outlets, onSelect, onBack, className }: SelectLocationProps) => {
  const [search, setSearch] = useState<string>('');

  const onChangeSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value);
  };

  const filteredOutlets: Outlet[] = useMemo(() => {
    return filterOutlets(outlets, search);
  }, [search, outlets]);

  const formatAddress = (outlet: Outlet) => {
    const formattedAddress = [];
    if (outlet.address) {
      formattedAddress.push(outlet.address);
    }
    if (outlet?.address2) {
      formattedAddress.push(outlet?.address2);
    }
    if (outlet.city) {
      formattedAddress.push(outlet.city);
    }
    if (outlet.state) {
      formattedAddress.push(outlet.state);
    }
    if (formattedAddress.length) {
      return formattedAddress.join(', ');
    }
    return 'No Address Available';
  };

  return (
    <SelectLocationWrap className={className}>
      <h2>{title}</h2>

      <TextInput placeholder="Search by address, city or zip code" onChange={onChangeSearch} className="mb-20" />

      <Row gutter={[20, 20]} className="mb-20">
        {filteredOutlets?.map((outlet, key) => (
          <Col key={key} lg={8} sm={12} xs={24}>
            <VoucherLocationCard
              name={outlet.name}
              address={formatAddress(outlet)}
              distance={outlet.distance}
              onClick={() => onSelect?.(outlet)}
              style={{ height: '100%' }}
            />
          </Col>
        ))}
      </Row>

      <BottomNavigation onBack={onBack} />
    </SelectLocationWrap>
  );
};

const SelectLocationWrap = styled.div`
  width: 70%;

  h2 {
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

export default SelectLocation;
