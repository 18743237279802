import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'constant/style';
import Lottie from 'react-lottie';
import VoucherHeader from 'components/VoucherHeader';
import SocialMediaLinks from 'components/SocialMediaLinks';
import thankyouAnimationData from 'lottie/send_mail.json';

const sendMailLottieOption = {
  loop: true,
  autoplay: true,
  animationData: thankyouAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface ThankYouProps {
  rating: number;
  voucher: boolean;
  voucherText?: string;
  brandColor: string;
  title: string;
  micrositeData?: any;
}

const ThankYou = ({ rating, voucher, voucherText, brandColor, title, micrositeData }: ThankYouProps) => {
  const { logoUrl, name } = micrositeData;

  return (
    <ThankYouWrap $brandColor={brandColor}>
      <div className="thankyou-page-content">
        <VoucherHeader logo={logoUrl} title={name} />
        <h2 className="main-heading">{title}</h2>

        {rating < 3 && (
          <div className="body-content mb-40">
            <div className="mb-30">
              We apologize for any inconvenience caused. You will be hearing from us very soon regarding your
              experience.
            </div>

            <div className="mb-40">A representative will be reaching out to you shortly.</div>
          </div>
        )}
        {rating == 3 && (
          <div className="body-content mb-40">
            Your feedback helps us improve. You will be hearing from us regarding your experience very soon.
          </div>
        )}

        <div className="body-content mb-40">
          {voucher &&
            (voucherText ??
              'An email has been sent to you with your voucher. Please read the instructions on how to redeem it.')}
        </div>
      </div>

      <SocialMediaLinks micrositeData={micrositeData} brandColor={brandColor} showTitle />

      {voucher && <Lottie options={sendMailLottieOption} height={230} width={300} />}
    </ThankYouWrap>
  );
};

interface ThankYouWrapProps {
  $brandColor: string;
}

const ThankYouWrap = styled.div<ThankYouWrapProps>`
  display: flex;
  flex-direction: column;
  text-align: center;

  .follow-text {
    font-size: 14px;
    color: var(--grey-dark);
    margin-top: 10px;
  }

  .social-media-links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .thankyou-page-content {
    flex: 1;

    .main-heading {
      color: ${(props) => props.$brandColor || 'var(--green-secondary)'};
      font-family: var(--font-family-medium);
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .body-content {
      font-size: 18px;
      line-height: 20px;
      font-family: var(--font-family-medium);
      font-weight: 600;
      text-align: center;
    }

    .additional-platform-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .review-platform {
        border: 1px solid var(--grey-light);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 30px;
        padding-right: 60px;
        cursor: pointer;
        margin-bottom: 20px;

        svg path {
          stroke: ${(props) => props.$brandColor || 'var(--green-secondary)'};
        }
      }
    }
  }

  .desk-feature-img {
    padding: 0px 40px;
  }

  .mobile-feature-img {
    display: none;
  }

  @media (max-width: ${breakpoints.laptop}) {
    margin-top: 0px;
    flex-direction: column;

    .thankyou-page-content {
      padding-right: 0px;
      margin-bottom: 30px;

      .additional-platform-wrap {
        flex-wrap: wrap;
        align-items: center;

        .review-platform {
          padding: 20px;
        }
      }

      .body-content {
        font-size: 14px;
      }
    }

    .desk-feature-img {
      display: none;
    }

    .mobile-feature-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default ThankYou;
