
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[slug]/survey/[id]",
      function () {
        return require("private-next-pages/[slug]/survey/[id].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[slug]/survey/[id]"])
      });
    }
  