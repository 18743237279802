import React from 'react';
import styled from 'styled-components';
import { hexToRgbA } from 'utils/styles';
import classNames from 'classnames';

interface PlatformCardProps {
  platformName: string;
  promoMessage?: string;
  platformLogo?: string | undefined | null;
  isYourWebsiteCategory?: boolean;
  isBrandLogo?: boolean;
  onClick: () => void;
  theme: Record<string, string>;
  brandLogoBg?: string;
  maxHeight?: string;
  renderLogo?: React.ReactNode;
}

const PlatformCard = ({
  platformName,
  promoMessage,
  platformLogo,
  isYourWebsiteCategory,
  isBrandLogo,
  onClick,
  theme,
  brandLogoBg,
  maxHeight,
  renderLogo,
}: PlatformCardProps) => {
  return (
    <PlatformCardWrap onClick={onClick} $theme={theme} $brandBg={brandLogoBg} $height={maxHeight}>
      <div className={classNames('logo-wrap', { 'brand-logo': isBrandLogo })}>
        {renderLogo ? (
          renderLogo
        ) : (
          <>
            {platformLogo ? (
              <img src={platformLogo} width={70} height={70} alt="" />
            ) : (
              <h5>{isYourWebsiteCategory ? 'Our Website' : platformName}</h5>
            )}
          </>
        )}
      </div>
      {promoMessage && <div className="promo-banner body-text">{promoMessage}</div>}
    </PlatformCardWrap>
  );
};

interface PlatformCardWrapProps {
  $height?: string;
  $brandBg?: string;
  $theme: Record<string, string>;
}

const PlatformCardWrap = styled.div<PlatformCardWrapProps>`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border: 1px solid var(--grey-light);
  height: ${(props) => props.$height || '180px'};
  border-radius: 10px;
  transition: all 300ms ease 0s;

  .logo-wrap {
    img {
      object-fit: contain;
    }
    h5 {
      margin-bottom: 0px;
    }
  }

  .brand-logo {
    background: ${(props) => props.$brandBg || 'transparent'};
    padding: 10px;
    border-radius: 2px;
  }

  .promo-banner {
    background: var(--sea-green-light);
    position: absolute;
    bottom: -2px;
    left: -1px;
    right: -1px;
    text-align: center;
    padding: 5px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--white);
  }

  :hover {
    background: ${({ $theme }) => hexToRgbA($theme?.highlightColor, 0.5) || 'var(--orange)'};
    border-color: ${({ $theme }) => hexToRgbA($theme?.highlightColor, 0.5) || 'var(--orange)'};
  }

  &.active-item {
    background: ${({ $theme }) => hexToRgbA($theme?.highlightColor, 0.5) || 'var(--orange)'};
  }

  .menu-img {
    object-fit: contain;
  }
`;

export default PlatformCard;
