import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import 'rmc-picker-scroll/assets/index.css';
import Picker from 'rmc-picker-scroll';
import styled from 'styled-components';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import moment, { Moment } from 'moment';

interface TimePickerModalProps {
  validate?: (value: string) => boolean;
  minStep?: number;
  disabledHours?: () => number[];
  disabled?: boolean;
  hourStep?: number;
  value?: string | Moment | null | undefined;
  defaultValue?: string | null;
  onChange: (value: Moment) => void;
  title?: string;
  suffix?: React.ReactNode;
}

const TimePickerModal = ({ defaultValue, value, onChange, title, minStep = 30, suffix }: TimePickerModalProps) => {
  const currentValue = value && moment.isMoment(value) ? value.format('hh:mm A') : '';
  const [selectedHour, setSelectedHour] = useState<number | string>(10);
  const [selectedMinute, setSelectedMinute] = useState<number | string>(0);
  const [selectedAbbreviation, setSelectedAbbreviation] = useState<string>('am');
  const [timepickerVisible, setTimepickerVisible] = useState(false);

  useEffect(() => {
    if (value && moment.isMoment(value)) {
      const formatted_hour = Number(value.format('hh'));
      const formatted_minute = Number(value.format('mm'));
      const formatted_suffix = value.format('a');
      setSelectedHour(formatted_hour);
      setSelectedMinute(formatted_minute);
      setSelectedAbbreviation(formatted_suffix);
    }
  }, [value, timepickerVisible]);

  const getHours = useMemo(() => {
    const items: React.ReactNode[] = [];
    for (let i = 1; i <= 12; i++) {
      items.push(
        <Picker.Item value={i} key={i}>
          {i > 9 ? i : `0${i}`}
        </Picker.Item>,
      );
    }
    return items;
  }, []);

  const getMinutes = useMemo(() => {
    const items: React.ReactNode[] = [];
    let interval = 0;
    for (let i = 0; i < 60 / minStep; i++) {
      items.push(
        <Picker.Item value={interval} key={i}>
          {interval > 9 ? interval : `0${interval}`}
        </Picker.Item>,
      );
      interval += minStep;
    }
    return items;
  }, [minStep]);

  const onChangeHour = (value: string) => {
    setSelectedHour(value);
  };

  const onChangeMinutes = (value: string) => {
    setSelectedMinute(value);
  };

  const onChangeAbbreviation = (value: string) => {
    setSelectedAbbreviation(value);
  };

  const handleSubmit = () => {
    setTimepickerVisible(false);
    const time24Format = moment(`${selectedHour}:${selectedMinute} ${selectedAbbreviation}`, 'hh:mm a').format('HH:mm');

    onChange && onChange(moment(time24Format, 'HH:mm'));
  };

  return (
    <>
      <TextInput
        placeholder="hh:mm"
        defaultValue={defaultValue || ''}
        value={currentValue}
        onClick={() => {
          setTimepickerVisible(true);
        }}
        readOnly
        className="start-time"
        suffix={suffix}
      />

      <Modal
        title={title}
        visible={timepickerVisible}
        onCancel={() => setTimepickerVisible(false)}
        centered
        footer={null}
        className="momos-modal"
      >
        <TimePickerWrap>
          <Picker selectedValue={selectedHour} onValueChange={onChangeHour}>
            {getHours}
          </Picker>
          <Picker selectedValue={selectedMinute} onValueChange={onChangeMinutes}>
            {getMinutes}
          </Picker>
          <Picker selectedValue={selectedAbbreviation} onValueChange={onChangeAbbreviation}>
            <Picker.Item value={'am'}>AM</Picker.Item>
            <Picker.Item value={'pm'}>PM</Picker.Item>
          </Picker>
        </TimePickerWrap>

        <TimePickerFooter className="timepicker-footer">
          <Button shape="round" type="default" className="mr-10" onClick={() => setTimepickerVisible(false)}>
            Cancel
          </Button>
          <Button shape="round" onClick={handleSubmit}>
            Set
          </Button>
        </TimePickerFooter>
      </Modal>
    </>
  );
};

const TimePickerWrap = styled.div`
  display: flex;
  padding: 10px 0px;

  .rmc-picker-item {
    font-size: 16px;
    font-family: var(--font-family-medium);
    color: var(--slate-gray-dark);
  }
`;

const TimePickerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default TimePickerModal;
