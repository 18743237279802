import { GoogleShareIcon, QuotationIcon } from 'assets/svg';
import Button from 'components/Button';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import request from 'config/request';
import endpoints from 'config/endpoints';
import { isClient } from 'utils/common';
import { find, get, isEmpty } from 'lodash';
import * as ga from 'lib/ga';
import { TRACKING } from 'constant/analytics';

const PlatformIcon: Record<string, ReactNode> = {
  GOOGLE: <GoogleShareIcon className="mb-20" />,
};

interface ShareProps {
  onSkip: () => void;
  voucher?: boolean;
  surveyResponseMetadata?: object;
  surveyId?: string | null;
  slug: string;
  links?: { name: keyof typeof PlatformIcon; link: string }[];
  reviewText?: string;
}

const ShareFeedback = ({ onSkip, voucher, surveyId, surveyResponseMetadata, slug, links, reviewText }: ShareProps) => {
  const googleLink = find(links, { name: 'GOOGLE' });

  const copyUrlToClipboard = (categoryName: string, link: string) => {
    ga.event(TRACKING.SURVEY.BUTTON.SHARE.GOOGLE);
    if (!isEmpty(surveyId) && !isEmpty(surveyResponseMetadata)) {
      // Capture the user click event for assisted reviews
      request.post(`${endpoints.momos.survey.default}/${slug}/${surveyId}/assisted-reviews`, {
        platform: categoryName,
        surveyRevisionId: get(surveyResponseMetadata, 'surveyRevisionId'),
        surveySubmitterId: get(surveyResponseMetadata, 'surveySubmitterId'),
        surveySubmissionId: get(surveyResponseMetadata, 'surveySubmissionId'),
      });
    }
    if (isClient() && navigator && reviewText) {
      copy(reviewText);
      window?.open(link, '_blank');
      onSkip();
    }
  };

  return (
    <Container>
      <TextHeader>
        {voucher && (
          <>
            Your voucher is on its way.
            <br />
          </>
        )}
        <span>One Last Step</span> to help us out
      </TextHeader>

      <GoogleShareIcon />

      <MainText>
        Post your Review
        <br />
        <span>on Google</span>
      </MainText>

      <Text>
        <QuotationIcon />
        <span>The following review has been copied to your clipboard:</span>
      </Text>
      <ReviewText>&ldquo;{reviewText}&rdquo;</ReviewText>

      <Button
        shape="round"
        className="w-100"
        onClick={() => {
          const { name = '', link = '' } = googleLink || {};
          copyUrlToClipboard(name, link);
        }}
      >
        Post on Google
      </Button>

      <NoText onClick={onSkip}>No, thank you</NoText>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextHeader = styled.div`
  margin: 0rem 0 1.5rem 0;
  padding-bottom: 1rem;
  font-size: 17px;
  font-weight: 700;
  font-family: var(--font-family-medium);
  color: var(--grey-dark);
  border-bottom: 1px solid var(--grey-light);
  width: 100%;
  text-align: center;

  span {
    font-family: var(--font-family-medium);
    color: var(--indian-red);
    font-size: 17px;
    display: inline-block;
  }
`;
const MainText = styled.div`
  margin-top: 1rem;
  font-size: 24px;
  color: var(--indian-red);
  font-family: var(--font-family-medium);
  text-align: center;
  line-height: 120%;

  span {
    font-size: 24px;
    font-family: var(--font-family-medium);
    color: var(--grey-dark);
  }
`;

const Text = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  padding: 1rem 2rem 0 1rem;

  span {
    font-family: var(--font-family-medium);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ReviewText = styled.div`
  margin: 0.5rem 0 2rem 0;
  font-size: 14px;
  color: var(--grey-dark);
  font-style: italic;
  padding-left: 2.25rem;
  padding-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 130%;
`;

const NoText = styled.div`
  font-size: 14px;
  font-family: var(--font-family-medium);
  color: var(--grey);
  margin: 1rem;
`;

export default ShareFeedback;
