import React from 'react';
import classnames from 'classnames';
import { Input, InputProps, InputNumber, InputNumberProps, AutoComplete } from 'antd';
import { TextAreaProps, PasswordProps } from 'antd/lib/input';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import styled from 'styled-components';

const AutoCompleteWrapper = styled(AutoComplete)`
  &.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 550px !important;
    height: 40px;
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const TextInput = ({ className, ...props }: { className?: string } & InputProps) => {
  return <Input size="large" className={classnames('momos-input', className)} {...props} autoComplete="off" />;
};

export default TextInput;

export const TextArea = ({ className, ...props }: { className?: string } & TextAreaProps) => {
  return (
    <Input.TextArea size="large" className={classnames('momos-textarea', className)} {...props} autoComplete="off" />
  );
};

export const NumberInput = ({ className, ...props }: { className?: string } & InputNumberProps) => {
  return <InputNumber size="large" className={classnames('momos-input', className)} {...props} autoComplete="off" />;
};

export const PasswordInput = ({ className, ...props }: { className?: string } & PasswordProps) => {
  return <Input.Password size="large" className={classnames('momos-input', className)} {...props} autoComplete="off" />;
};

export const CustomAutoComplete = ({ children, ...props }: { children?: React.ReactNode } & AutoCompleteProps) => {
  return (
    // @ts-ignore
    <AutoCompleteWrapper size="large" {...props}>
      {children}
    </AutoCompleteWrapper>
  );
};

export const SmallTextInput = ({ className, ...props }: { className?: string } & InputProps) => {
  return <Input className={classnames('momos-small-input', className)} {...props} autoComplete="off" />;
};

export const SmallNumberInput = ({ className, ...props }: { className?: string } & InputNumberProps) => {
  return <InputNumber className={classnames('momos-small-input', className)} {...props} autoComplete="off" />;
};
