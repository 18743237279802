import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'constant/style';
import { Col, Row } from 'components/Layout/RowColLayout';
import Button from 'components/Button';
import { UserOutlined } from '@ant-design/icons';
import { Staff } from 'utils/types';

interface SelectStaffProps {
  data: Record<string, any> | undefined;
  onSelect: (staff: Staff) => void;
  onSkip: () => void;
  staffList: Staff[];
}

const SelectStaff = ({ data, onSelect, onSkip, staffList }: SelectStaffProps) => {
  return (
    <SelectStaffWrap>
      <h2>{data?.questionData?.text}</h2>

      <Row gutter={[20, 10]} className="staff-list-wrap">
        {staffList.map((staff) => (
          <Col lg={6} sm={8} xs={12} key={staff.id}>
            <div className="staff-card" onClick={() => onSelect(staff)}>
              {staff.profile_url ? (
                <img src={staff.profile_url} alt="avatar" />
              ) : (
                <div className="staff-placeholder">
                  <UserOutlined style={{ fontSize: 60 }} />
                </div>
              )}
              <div className="staff-name">{staff.name}</div>
            </div>
          </Col>
        ))}
        <Col lg={6} sm={8} xs={12}>
          <div className="staff-card" onClick={() => onSelect({ id: null, name: 'Someone Else', profile_url: null })}>
            <div className="staff-placeholder">
              <UserOutlined style={{ fontSize: 60 }} />
            </div>
            <div className="staff-name">Someone Else</div>
          </div>
        </Col>
      </Row>

      {data?.config?.skippable && (
        <div className="skip-action">
          <Button block className="skip-button" shape="round" onClick={onSkip}>
            Skip
          </Button>
        </div>
      )}
    </SelectStaffWrap>
  );
};

const SelectStaffWrap = styled.div`
  width: 70%;

  h2 {
    margin-bottom: 40px;
  }

  .staff-list-wrap {
    margin-bottom: 60px;
    .staff-card {
      cursor: pointer;

      img {
        border-radius: 10px;
        width: 100%;
        height: 140px;
        object-fit: cover;
        margin-bottom: 10px;
      }
      .staff-placeholder {
        width: 100%;
        height: 140px;
        border: 1px solid var(--grey-dark);
        border-radius: 10px;
        margin-bottom: 10px;
        background: var(--smoke-white);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .staff-name {
        font-family: var(--font-family-medium);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--slate-gray-dark);
      }
    }
  }

  .skip-action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(360deg, var(--white) 10.66%, rgba(255, 255, 255, 0) 100%);
    padding: 20px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
  }
`;

export default SelectStaff;
